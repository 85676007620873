<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div
      class="container-fluid mt--9"
      v-if="formEditCategory.id"
    >
      <div class="card shadow">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Ubah Kategori</h3>
        </div>
        <div class="card-body">
          <el-form
            label-position="top"
            label-width="100px"
            ref="formEditCategoryRef"
            :model="formEditCategory"
            :rules="formEditCategoryRules"
            @submit.prevent="submitFormEditCategory"
          >
            <el-form-item
              label="Nama"
              prop="name"
            >
              <el-input v-model="formEditCategory.name"></el-input>
            </el-form-item>

            <div class="mt-4">
              <base-button
                native-type="submit"
                :disabled="updateCategoryPromised.state.loading"
                type="primary"
              >{{ !updateCategoryPromised.state.loading ? 'Simpan Perubahan' : 'Simpan Perubahan...' }}</base-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAxios from "@/composables/useAxios";
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";

import categoryService from '@/services/Category'
import useSwal from "@/composables/useSwal";
import { useRoute, useRouter } from "vue-router";
import { useMeta } from "vue-meta";
import usePageMeta from "@/composables/usePageMeta";

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    const initFormEditCategory = () => {
      return {
        name: ''
      }
    }

    const state = reactive({
      formEditCategory: initFormEditCategory(),
      formEditCategoryRules: {
        name: [
          { required: true, message: 'Please enter name', trigger: ['change', 'blur'] },
        ]
      }
    })

    useMeta(usePageMeta(route).pageMeta)

    const paramId = computed(() => route.params.id)
    const formEditCategoryRef = ref()

    const getCategoryPromised = useAxios((id, payload = {}) => {
      return categoryService.superadmin.getCategory(id, payload)
    })

    const getCategory = async () => {
      await getCategoryPromised.createPromise(paramId.value)

      if (getCategoryPromised.state.response) {
        state.formEditCategory = getCategoryPromised.state.data
      } else if (getCategoryPromised.state.error) {
        await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Gagal mendapatkan kategori',
            text: getCategoryPromised.state.errorMessage,
            willClose: () => {
              router.push({
                name: 'Kelola Kategori'
              })
            },
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }

    const updateCategoryPromised = useAxios((id, payload = {}) => {
      return categoryService.superadmin.updateCategory(id, payload)
    }, {
      withIndicator: true
    })

    const submitFormEditCategory = async () => {
      formEditCategoryRef.value.validate(async (valid) => {
        if (!valid) {
          return
        }

        await updateCategoryPromised.createPromise(paramId.value, {
          data: state.formEditCategory
        })

        if (updateCategoryPromised.state.response) {
          state.formEditJobVacancy = initFormEditCategory()
          formEditCategoryRef.value.resetFields()

          Swal.fire({
            icon: 'success',
            title: 'Berhasil memperbarui kategori',
            willClose: () => {
              router.push({
                name: 'Kelola Kategori'
              })
            }
          })
        } else if (updateCategoryPromised.state.error) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal memperbarui kategori',
            text: updateCategoryPromised.state.errorMessage,
          })
        }
      })
    }

    onMounted(async () => {
      await getCategory()
    })

    return {
      ...toRefs(state),
      formEditCategoryRef,
      updateCategoryPromised,
      submitFormEditCategory
    }
  }
})
</script>

<style lang="scss">
.el-form-item {
  margin-bottom: 10px;
  .el-form-item__label {
    margin-bottom: 0;
    padding: 0;
  }
}
</style>