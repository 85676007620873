import { apiClientAuth } from '@/utils/apiClient'

export default {
  getCategories(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/categories',
      ...payload
    })
  },
  getCategory(id, payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/categories/' + id,
      ...payload
    })
  },
  createCategory(payload) {
    return apiClientAuth({
      method: 'post',
      url: '/api/superadmin/categories',
      ...payload
    })
  },
  updateCategory(id, payload) {
    return apiClientAuth({
      method: 'put',
      url: '/api/superadmin/categories/' + id,
      ...payload
    })
  },
  deleteCategories(payload) {
    return apiClientAuth({
      method: 'delete',
      url: '/api/superadmin/categories',
      ...payload
    })
  },
}