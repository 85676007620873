<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9">
      <div class="card shadow">
        <div class="card-header bg-transparent">
          <h3 class="mb-0">Tambah Kategori</h3>
        </div>
        <div class="card-body">
          <el-form
            label-position="top"
            label-width="100px"
            ref="formAddCategoryRef"
            :model="formAddCategory"
            :rules="formAddCategoryRules"
            @submit.prevent="submitFormAddCategory"
          >
            <el-form-item
              label="Nama"
              prop="name"
            >
              <el-input v-model="formAddCategory.name"></el-input>
            </el-form-item>

            <div class="mt-4">
              <base-button
                native-type="submit"
                :disabled="createCategoryPromised.state.loading"
                type="primary"
              >{{ !createCategoryPromised.state.loading ? 'Tambah' : 'Tambah...' }}</base-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAxios from "@/composables/useAxios";
import { defineComponent, reactive, ref, toRefs } from "vue";

import categoryService from '@/services/Category'
import useSwal from "@/composables/useSwal";
import { useRoute, useRouter } from "vue-router";
import { useMeta } from "vue-meta";
import usePageMeta from "@/composables/usePageMeta";

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const Swal = useSwal()

    const initFormAddCategory = () => {
      return {
        name: ''
      }
    }

    const state = reactive({
      formAddCategory: initFormAddCategory(),
      formAddCategoryRules: {
        name: [
          { required: true, message: 'Silakan masukkan nama katagori', trigger: ['change', 'blur'] },
        ]
      }
    })

    useMeta(usePageMeta(route).pageMeta)

    const formAddCategoryRef = ref()

    const createCategoryPromised = useAxios((payload = {}) => {
      return categoryService.superadmin.createCategory(payload)
    }, {
      withIndicator: true
    })

    const submitFormAddCategory = async () => {
      formAddCategoryRef.value.validate(async (valid) => {
        if (!valid) {
          return
        }

        await createCategoryPromised.createPromise({
          data: state.formAddCategory
        })

        if (createCategoryPromised.state.response) {
          state.formAddJobVacancy = initFormAddCategory()
          formAddCategoryRef.value.resetFields()

          Swal.fire({
            icon: 'success',
            title: 'Berhasil membuat kategori',
            willClose: () => {
              router.push({
                name: 'Kelola Kategori'
              })
            }
          })
        } else if (createCategoryPromised.state.error) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal membuat kategori',
            text: createCategoryPromised.state.errorMessage,
          })
        }
      })
    }

    return {
      ...toRefs(state),
      formAddCategoryRef,
      createCategoryPromised,
      submitFormAddCategory
    }
  }
})
</script>

<style lang="scss">
.el-form-item {
  margin-bottom: 10px;
  .el-form-item__label {
    margin-bottom: 0;
    padding: 0;
  }
}
</style>